import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { motion } from "framer-motion";

import Hero from "../components/Hero";
import Header from "../components/Header";
import AboutText from "../components/AboutText";
import Partners from "../components/Partners";
import Services from "../components/Services";
import Footer from "../components/Footer";

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Home() {
  let titleAnim = useRef(null);
  let subtitleAnim = useRef(null);
  let tl = gsap.timeline();
  const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

  useEffect(() => {
    tl.from(subtitleAnim, {
      y: 15,
      opacity: 0,
      delay: 1,
      duration: 0.5,
      ease: "power3.inOut",
    })
      .from(titleAnim, {
        y: 15,
        opacity: 0,
        duration: 0.5,
        ease: "power3.inOut",
      })
      .from(".scroll-down", {
        y: 15,
        opacity: 0,
        duration: 0.5,
        ease: "power3.inOut",
      });
  }, [titleAnim, subtitleAnim, tl]);

  return (
    <div>
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: "-100%" }}
        exit={{ x: 0 }}
        transition={LoadingTransition}
        className="page-trans"
      ></motion.div>
      <Header />
      <video autoPlay muted loop>
        <source src="assets/images/video.mp4" type="video/mp4" />
      </video>
      <Hero>
        <h6 ref={(el) => (subtitleAnim = el)} className="sub-title">
          {" "}
        </h6>
        <h1 ref={(el) => (titleAnim = el)} className="big-title">
          Bespoke software solutions <br /> for clinical research and practice.
        </h1>
      </Hero>
      <section id="aboutSection">
        <AboutText />
        <Partners />
      </section>
      <Services>
        <h2 className="big-title">
          We can take your ideas <br /> to a whole new level.
        </h2>
        <p>
          Our team covers all aspects of software development with care and
          great attention to detail. Trust is paramount in the healthcare
          industry, therefore, we design applications that are privacy and
          security first.
        </p>
        <Link to="/contact">Become our partner</Link>
      </Services>
      <Footer />
    </div>
  );
}

export default Home;
