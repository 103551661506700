import React, { useState } from "react";
import FormSignup from "./FormSignup";
import FormSuccess from "./FormSuccess";

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm({ username, email, subject, message }) {
    setIsSubmitted(true);
    const mailto = `mailto:${encodeURIComponent(
      "Silicon Albion Enquires"
    )}<hello@siliconalbion.com>?from=${encodeURIComponent(
      username
    )}<${email}>&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;
    window.location.href = mailto;
  }

  return (
    <>
      <div className="form-container">
        {!isSubmitted ? (
          <FormSignup submitForm={submitForm} />
        ) : (
          <FormSuccess />
        )}
      </div>
    </>
  );
};

export default Form;
