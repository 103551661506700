import React from "react";

function AboutText() {
  return (
    <section id="aboutStory" className="section">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-left anim-bot">
            <h2 className="big-title anim-bot">
              Our mission is to empower medical professionals to do their best
              work, without distractions.
            </h2>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-right anim-right">
            <div className="story">
              <p className="big-p">
                Silicon Albion is a software development consultancy with a
                focus on medical research application development. Our
                multidisciplinary team can transform your ideas into software
                products that utilise the latest technologies for the best user
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutText;
