import React from "react";

import PartnerItem from "../elements/PartnerItem";

function Partners() {
  const partners = [
    {
      name: "UCLA Health",
      image: "assets/images/ucla-white.png",
      url: "https://www.uclahealth.org",
    },
    {
      name: "Klinikum Rechts der Isar",
      image: "assets/images/mri-white.svg",
      url: "https://www.mri.tum.de",
    },
  ];

  const displayPartners = partners.map((item, index) => (
    <PartnerItem
      key={index}
      name={item.name}
      image={item.image}
      url={item.url}
    ></PartnerItem>
  ));

  return (
    <section id="partnersSection">
      <div className="container-fluid-small">
        <div className="row align-items-baseline">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 services-top services-left anim-bot">
            <h2 className="big-title">We've built solutions for...</h2>
          </div>
          {displayPartners}
        </div>
      </div>
    </section>
  );
}

export default Partners;
