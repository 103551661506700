import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const date = new Date().getFullYear();

  const socialLinks = [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/silicon-albion",
    },
    // {
    //     name: 'Facebook',
    //     url: 'https://www.facebook.com/'
    // },
    // {
    //     name: 'Instagram',
    //     url: 'https://www.instagram.com/'
    // },
    // {
    //     name: 'Twitter',
    //     url: 'https://www.twitter.com/'
    // }
  ];

  return (
    <footer id="footerSection">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 footer-title anim-bot">
            <h2 className="big-title">Let's work together.</h2>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 footer-project anim-bot">
            <Link to="/contact">Enquire about a project</Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-4 copyright">
            <p>Copyright © {date} Silicon Albion Ltd</p>
            <div className="footer-menu">
              <ul>
                {socialLinks.map((item, i) => (
                  <li key={i}>
                    <a href={item.url}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-1 footer-offset"></div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 contact-box contact-mail">
            <h5 className="sub-title">Get in touch</h5>
            <ul>
              <li>hello@siliconalbion.com</li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 contact-box contact-address">
            <h5 className="sub-title">Registered office</h5>
            <ul>
              <li>85 Great Portland Street</li>
              <li>First Floor</li>
              <li>W1W 7LT London</li>
              <li>United Kingdom</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
