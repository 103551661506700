import React from "react";

function PartnerItem(props) {
  const { name, image, url } = props;

  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
      <a href={url} target="_blank" rel="noreferrer">
        <img className="img-fluid" src={image} alt={name} />
      </a>
    </div>
  );
}

export default PartnerItem;
