import React from "react";
import { gsap } from "gsap";
import { motion } from "framer-motion";

import Header from "../components/Header";
import ContactBody from "../components/ContactBody";

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Contact() {
  const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

  return (
    <div>
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: "-100%" }}
        exit={{ x: 0 }}
        transition={LoadingTransition}
        className="page-trans"
      ></motion.div>
      <Header />
      <ContactBody />
    </div>
  );
}

export default Contact;
