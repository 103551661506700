import React from "react";
import Icofont from "react-icofont";

const FormSuccess = () => {
  return (
    <div className="succes-message">
      <Icofont icon="check-circled" />
      <h1 className="big-title">Success!</h1>
      <p className="sub-title">
        The message has been sent!
        <br />
        Our customer support team will look into it
        <br />
        and will respond as soon as possible.
      </p>
    </div>
  );
};

export default FormSuccess;
